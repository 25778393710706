<template>
  <article>
    <h1>공지사항</h1>
    <section class="sub3_detail">
      <div class="right">
        <router-link to="/sub3" tag="p">목록</router-link>
        <p onclick="$('#postTr').click();">다음글<i class="fas fa-caret-down"></i></p>
      </div>
      <table>
        <thead>
          <tr>
            <th colspan="4" id="boardTitle"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>첨부파일<i class="far fa-clone"></i></td>
            <td> </td> 
            <td id="boardInput"></td>
            <td id="boardDate"></td>
          </tr>
          <tr>
            <td colspan="4" id="boardContents"></td>
          </tr>
          <tr id="postTr">
            <td>다음글 <i class="fas fa-caret-up"></i></td>
            <td id="postTitle"></td>
            <td></td>
            <td></td>
          </tr>
          <tr id="preTr">
            <td>이전글 <i class="fas fa-caret-down"></i></td>
            <td id="preTitle"></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div class="list">
          <router-link to="/sub3" tag="p">목록</router-link>
        </div>
    </section>
  </article>
</template>
<script>
  export default{
    mounted(){
      let boardNo = this.$route.query.boardNo;
      getNoticeDetail({
        boardNo: boardNo
        , callback: "noticeDetailCallback"
      });
    }
  }
</script>
